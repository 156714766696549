import { Environment } from '@app/core/environment';

export const environment: Environment = {
    production: false,
    apiUrl: 'https://doable-adoormore-api.testing.zupit.software',
    logging: {
        connectionString: '',
        appName: 'frontend',
    },
    auth: {
        redirectUri: 'https://doable-adoormore.testing.zupit.software',
        issuer: 'https://<identity-provider>.testing.zupit.software/keycloak/realms/doable-adoormore',
        clientId: 'doable-adoormore',
        showDebugInformation: true,
    },
    recaptcha: {
        siteKey: '6LdpVSIqAAAAAG-2TlykRF-DFuPgBK8QgJw9ZObq',
    },
    version: '',
    jiraIssueCollector: {
        helpCenter: {
            // eslint-disable-next-line max-len
            url: 'https://zupit-pro.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3ddrgv/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=it-IT&collectorId=6371f214',
            customFieldKeys: {
                language: 'customfield_11411',
                username: 'customfield_11412',
            },
        },
        ambassadorInfo: {
            // eslint-disable-next-line max-len
            url: 'https://zupit-pro.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/g2slup/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=47b9fb3b',
            customFieldKeys: {
                language: 'customfield_11411',
            },
        },
    },
    htmlFontSize: 0.8125, // This value must be the same as in styles.scss file
};
