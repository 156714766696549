/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EnablingServiceGroupResponse } from '../models/enabling-service-group-response';
import { EnablingServiceVideoDetailResponse } from '../models/enabling-service-video-detail-response';
import { getEnablingServices } from '../fn/ambassador-enabling-services/get-enabling-services';
import { GetEnablingServices$Params } from '../fn/ambassador-enabling-services/get-enabling-services';
import { getEnablingServiceVideoDetail } from '../fn/ambassador-enabling-services/get-enabling-service-video-detail';
import { GetEnablingServiceVideoDetail$Params } from '../fn/ambassador-enabling-services/get-enabling-service-video-detail';
import { getPurchasedServices } from '../fn/ambassador-enabling-services/get-purchased-services';
import { GetPurchasedServices$Params } from '../fn/ambassador-enabling-services/get-purchased-services';
import { PurchasedServicesResponse } from '../models/purchased-services-response';

@Injectable({ providedIn: 'root' })
export class AmbassadorEnablingServicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getEnablingServices()` */
  static readonly GetEnablingServicesPath = '/api/ambassador-enabling-services/{serviceType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnablingServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnablingServices$Response(params: GetEnablingServices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EnablingServiceGroupResponse>>> {
    return getEnablingServices(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEnablingServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnablingServices(params: GetEnablingServices$Params, context?: HttpContext): Observable<Array<EnablingServiceGroupResponse>> {
    return this.getEnablingServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnablingServiceGroupResponse>>): Array<EnablingServiceGroupResponse> => r.body)
    );
  }

  /** Path part for operation `getEnablingServiceVideoDetail()` */
  static readonly GetEnablingServiceVideoDetailPath = '/api/ambassador-enabling-services/video/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnablingServiceVideoDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnablingServiceVideoDetail$Response(params: GetEnablingServiceVideoDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<EnablingServiceVideoDetailResponse>> {
    return getEnablingServiceVideoDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEnablingServiceVideoDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnablingServiceVideoDetail(params: GetEnablingServiceVideoDetail$Params, context?: HttpContext): Observable<EnablingServiceVideoDetailResponse> {
    return this.getEnablingServiceVideoDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnablingServiceVideoDetailResponse>): EnablingServiceVideoDetailResponse => r.body)
    );
  }

  /** Path part for operation `getPurchasedServices()` */
  static readonly GetPurchasedServicesPath = '/api/ambassador-enabling-services/purchased';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPurchasedServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchasedServices$Response(params?: GetPurchasedServices$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchasedServicesResponse>> {
    return getPurchasedServices(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPurchasedServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchasedServices(params?: GetPurchasedServices$Params, context?: HttpContext): Observable<PurchasedServicesResponse> {
    return this.getPurchasedServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchasedServicesResponse>): PurchasedServicesResponse => r.body)
    );
  }

}
