/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ActivityService } from './services/activity.service';
import { AmbassadorEnablingServicesService } from './services/ambassador-enabling-services.service';
import { AmplifyService } from './services/amplify.service';
import { AuthService } from './services/auth.service';
import { ChatService } from './services/chat.service';
import { CompanyService } from './services/company.service';
import { CompanyMemberService } from './services/company-member.service';
import { CompanyPointService } from './services/company-point.service';
import { CountriesService } from './services/countries.service';
import { DocumentService } from './services/document.service';
import { DummiesService } from './services/dummies.service';
import { EditorialService } from './services/editorial.service';
import { LocalizationService } from './services/localization.service';
import { MembershipService } from './services/membership.service';
import { NotificationService } from './services/notification.service';
import { SupplyChainService } from './services/supply-chain.service';
import { UserService } from './services/user.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ActivityService,
    AmbassadorEnablingServicesService,
    AmplifyService,
    AuthService,
    ChatService,
    CompanyService,
    CompanyMemberService,
    CompanyPointService,
    CountriesService,
    DocumentService,
    DummiesService,
    EditorialService,
    LocalizationService,
    MembershipService,
    NotificationService,
    SupplyChainService,
    UserService,
    ApiConfiguration
  ],
})
export class DoableApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<DoableApiModule> {
    return {
      ngModule: DoableApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: DoableApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('DoableApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
