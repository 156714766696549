import { InjectionToken } from '@angular/core';
import { AppInsightsConfigModel } from '@omaxel/angular-ms-app-insights';
import { AuthConfig } from 'angular-oauth2-oidc';

export interface Environment {
    production: boolean;
    apiUrl: string;
    logging: AppInsightsConfigModel;
    auth?: AuthConfig;
    version: string;
    recaptcha: {
        siteKey: string;
    };
    clarityProjectId?: string;
    jiraIssueCollector: {
        helpCenter: {
            url: string;
            customFieldKeys: {
                username: string;
                language: string;
            };
        };
        ambassadorInfo: {
            url: string;
            customFieldKeys: {
                language: string;
            };
        };
    };
    htmlFontSize: number;
}

export const ENVIRONMENT: InjectionToken<Environment> = new InjectionToken('ENVIRONMENT');
