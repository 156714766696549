import { DestroyRef, Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent, ReplaySubject, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { mobileMaxWidth } from '../constants/responsive-width';

@Injectable({
    providedIn: 'root',
})
export class MediaQueryService {
    private readonly destroyRef = inject(DestroyRef);
    mobileMediaQueryChanged$ = new ReplaySubject(1);

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: object,
        @Inject(DOCUMENT) private document: Document,
    ) {
        const isBrowser = isPlatformBrowser(this.platformId);

        if (isBrowser) {
            const mql = this.document.defaultView?.matchMedia(`(max-width: ${mobileMaxWidth})`);
            if (!mql) {
                throw new Error('MediaQueryList is not supported!');
            }

            fromEvent<MediaQueryListEvent>(mql, 'change')
                .pipe(
                    tap((e) => {
                        if (e.matches) {
                            this.mobileMediaQueryChanged$.next(true);
                            return;
                        }
                        this.mobileMediaQueryChanged$.next(false);
                    }),
                    takeUntilDestroyed(this.destroyRef),
                )
                .subscribe();
        } else {
            this.mobileMediaQueryChanged$.next(false);
        }
    }
}
