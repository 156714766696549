/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AmbassadorPurchaseSessionResponse } from '../models/ambassador-purchase-session-response';
import { applyCompanyToSilverAmbassador } from '../fn/company/apply-company-to-silver-ambassador';
import { ApplyCompanyToSilverAmbassador$Params } from '../fn/company/apply-company-to-silver-ambassador';
import { checkCompanyByInvitationCode } from '../fn/company/check-company-by-invitation-code';
import { CheckCompanyByInvitationCode$Params } from '../fn/company/check-company-by-invitation-code';
import { CheckCompanyByInvitationCodeResponse } from '../models/check-company-by-invitation-code-response';
import { checkVat } from '../fn/company/check-vat';
import { CheckVat$Params } from '../fn/company/check-vat';
import { CompanyCategoriesResponse } from '../models/company-categories-response';
import { CompanyDetailResponse } from '../models/company-detail-response';
import { CompanyResponsePaging } from '../models/company-response-paging';
import { CompanyRolesResponse } from '../models/company-roles-response';
import { CompanySummaryResponse } from '../models/company-summary-response';
import { CompanyTagDto } from '../models/company-tag-dto';
import { createCompanyTag } from '../fn/company/create-company-tag';
import { CreateCompanyTag$Params } from '../fn/company/create-company-tag';
import { createGoldAmbassadorPurchaseSession } from '../fn/company/create-gold-ambassador-purchase-session';
import { CreateGoldAmbassadorPurchaseSession$Params } from '../fn/company/create-gold-ambassador-purchase-session';
import { createSilverAmbassadorPurchaseSession } from '../fn/company/create-silver-ambassador-purchase-session';
import { CreateSilverAmbassadorPurchaseSession$Params } from '../fn/company/create-silver-ambassador-purchase-session';
import { deleteCompanyTag } from '../fn/company/delete-company-tag';
import { DeleteCompanyTag$Params } from '../fn/company/delete-company-tag';
import { FollowedCompanyResponse } from '../models/followed-company-response';
import { getCompanies } from '../fn/company/get-companies';
import { GetCompanies$Params } from '../fn/company/get-companies';
import { getCompanyAssociatedTags } from '../fn/company/get-company-associated-tags';
import { GetCompanyAssociatedTags$Params } from '../fn/company/get-company-associated-tags';
import { getCompanyCategories } from '../fn/company/get-company-categories';
import { GetCompanyCategories$Params } from '../fn/company/get-company-categories';
import { getCompanyDetail } from '../fn/company/get-company-detail';
import { GetCompanyDetail$Params } from '../fn/company/get-company-detail';
import { getCompanyRoles } from '../fn/company/get-company-roles';
import { GetCompanyRoles$Params } from '../fn/company/get-company-roles';
import { getCompanySummary } from '../fn/company/get-company-summary';
import { GetCompanySummary$Params } from '../fn/company/get-company-summary';
import { getCompanyTags } from '../fn/company/get-company-tags';
import { GetCompanyTags$Params } from '../fn/company/get-company-tags';
import { getFollowedCompanies } from '../fn/company/get-followed-companies';
import { GetFollowedCompanies$Params } from '../fn/company/get-followed-companies';
import { registerCompanyByInvitationCode } from '../fn/company/register-company-by-invitation-code';
import { RegisterCompanyByInvitationCode$Params } from '../fn/company/register-company-by-invitation-code';
import { registerCompanyByInvitationCodeOtp } from '../fn/company/register-company-by-invitation-code-otp';
import { RegisterCompanyByInvitationCodeOtp$Params } from '../fn/company/register-company-by-invitation-code-otp';
import { saveStripeAmbassadorPurchaseSessionDetailsFailed } from '../fn/company/save-stripe-ambassador-purchase-session-details-failed';
import { SaveStripeAmbassadorPurchaseSessionDetailsFailed$Params } from '../fn/company/save-stripe-ambassador-purchase-session-details-failed';
import { saveStripeAmbassadorPurchaseSessionDetailsSuccess } from '../fn/company/save-stripe-ambassador-purchase-session-details-success';
import { SaveStripeAmbassadorPurchaseSessionDetailsSuccess$Params } from '../fn/company/save-stripe-ambassador-purchase-session-details-success';
import { setCompanyAssociatedTags } from '../fn/company/set-company-associated-tags';
import { SetCompanyAssociatedTags$Params } from '../fn/company/set-company-associated-tags';
import { toggleFollow } from '../fn/company/toggle-follow';
import { ToggleFollow$Params } from '../fn/company/toggle-follow';
import { updateCompanyTag } from '../fn/company/update-company-tag';
import { UpdateCompanyTag$Params } from '../fn/company/update-company-tag';
import { VatCheck } from '../models/vat-check';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `applyCompanyToSilverAmbassador()` */
  static readonly ApplyCompanyToSilverAmbassadorPath = '/api/apply-company-to-silver-ambassador';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applyCompanyToSilverAmbassador()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applyCompanyToSilverAmbassador$Response(params: ApplyCompanyToSilverAmbassador$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return applyCompanyToSilverAmbassador(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applyCompanyToSilverAmbassador$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applyCompanyToSilverAmbassador(params: ApplyCompanyToSilverAmbassador$Params, context?: HttpContext): Observable<void> {
    return this.applyCompanyToSilverAmbassador$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `checkCompanyByInvitationCode()` */
  static readonly CheckCompanyByInvitationCodePath = '/api/company/check-invitation-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkCompanyByInvitationCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCompanyByInvitationCode$Response(params: CheckCompanyByInvitationCode$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckCompanyByInvitationCodeResponse>> {
    return checkCompanyByInvitationCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkCompanyByInvitationCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCompanyByInvitationCode(params: CheckCompanyByInvitationCode$Params, context?: HttpContext): Observable<CheckCompanyByInvitationCodeResponse> {
    return this.checkCompanyByInvitationCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckCompanyByInvitationCodeResponse>): CheckCompanyByInvitationCodeResponse => r.body)
    );
  }

  /** Path part for operation `checkVat()` */
  static readonly CheckVatPath = '/api/companies/vat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkVat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkVat$Response(params: CheckVat$Params, context?: HttpContext): Observable<StrictHttpResponse<VatCheck>> {
    return checkVat(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkVat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkVat(params: CheckVat$Params, context?: HttpContext): Observable<VatCheck> {
    return this.checkVat$Response(params, context).pipe(
      map((r: StrictHttpResponse<VatCheck>): VatCheck => r.body)
    );
  }

  /** Path part for operation `getCompanyTags()` */
  static readonly GetCompanyTagsPath = '/api/company/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyTags$Response(params?: GetCompanyTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyTagDto>>> {
    return getCompanyTags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyTags(params?: GetCompanyTags$Params, context?: HttpContext): Observable<Array<CompanyTagDto>> {
    return this.getCompanyTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyTagDto>>): Array<CompanyTagDto> => r.body)
    );
  }

  /** Path part for operation `createCompanyTag()` */
  static readonly CreateCompanyTagPath = '/api/company/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompanyTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyTag$Response(params: CreateCompanyTag$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyTagDto>> {
    return createCompanyTag(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompanyTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyTag(params: CreateCompanyTag$Params, context?: HttpContext): Observable<CompanyTagDto> {
    return this.createCompanyTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyTagDto>): CompanyTagDto => r.body)
    );
  }

  /** Path part for operation `createGoldAmbassadorPurchaseSession()` */
  static readonly CreateGoldAmbassadorPurchaseSessionPath = '/api/company/checkout-gold-ambassador-services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createGoldAmbassadorPurchaseSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  createGoldAmbassadorPurchaseSession$Response(params?: CreateGoldAmbassadorPurchaseSession$Params, context?: HttpContext): Observable<StrictHttpResponse<AmbassadorPurchaseSessionResponse>> {
    return createGoldAmbassadorPurchaseSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createGoldAmbassadorPurchaseSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createGoldAmbassadorPurchaseSession(params?: CreateGoldAmbassadorPurchaseSession$Params, context?: HttpContext): Observable<AmbassadorPurchaseSessionResponse> {
    return this.createGoldAmbassadorPurchaseSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<AmbassadorPurchaseSessionResponse>): AmbassadorPurchaseSessionResponse => r.body)
    );
  }

  /** Path part for operation `createSilverAmbassadorPurchaseSession()` */
  static readonly CreateSilverAmbassadorPurchaseSessionPath = '/api/company/checkout-silver-ambassador-services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSilverAmbassadorPurchaseSession()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSilverAmbassadorPurchaseSession$Response(params: CreateSilverAmbassadorPurchaseSession$Params, context?: HttpContext): Observable<StrictHttpResponse<AmbassadorPurchaseSessionResponse>> {
    return createSilverAmbassadorPurchaseSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSilverAmbassadorPurchaseSession$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSilverAmbassadorPurchaseSession(params: CreateSilverAmbassadorPurchaseSession$Params, context?: HttpContext): Observable<AmbassadorPurchaseSessionResponse> {
    return this.createSilverAmbassadorPurchaseSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<AmbassadorPurchaseSessionResponse>): AmbassadorPurchaseSessionResponse => r.body)
    );
  }

  /** Path part for operation `updateCompanyTag()` */
  static readonly UpdateCompanyTagPath = '/api/company/tags/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyTag$Response(params: UpdateCompanyTag$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyTagDto>> {
    return updateCompanyTag(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyTag(params: UpdateCompanyTag$Params, context?: HttpContext): Observable<CompanyTagDto> {
    return this.updateCompanyTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyTagDto>): CompanyTagDto => r.body)
    );
  }

  /** Path part for operation `deleteCompanyTag()` */
  static readonly DeleteCompanyTagPath = '/api/company/tags/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyTag$Response(params: DeleteCompanyTag$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCompanyTag(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyTag(params: DeleteCompanyTag$Params, context?: HttpContext): Observable<void> {
    return this.deleteCompanyTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCompanies()` */
  static readonly GetCompaniesPath = '/api/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies$Response(params: GetCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponsePaging>> {
    return getCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies(params: GetCompanies$Params, context?: HttpContext): Observable<CompanyResponsePaging> {
    return this.getCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponsePaging>): CompanyResponsePaging => r.body)
    );
  }

  /** Path part for operation `getCompanyAssociatedTags()` */
  static readonly GetCompanyAssociatedTagsPath = '/api/companies/{id}/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyAssociatedTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyAssociatedTags$Response(params: GetCompanyAssociatedTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyTagDto>>> {
    return getCompanyAssociatedTags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyAssociatedTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyAssociatedTags(params: GetCompanyAssociatedTags$Params, context?: HttpContext): Observable<Array<CompanyTagDto>> {
    return this.getCompanyAssociatedTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyTagDto>>): Array<CompanyTagDto> => r.body)
    );
  }

  /** Path part for operation `setCompanyAssociatedTags()` */
  static readonly SetCompanyAssociatedTagsPath = '/api/companies/{id}/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCompanyAssociatedTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCompanyAssociatedTags$Response(params: SetCompanyAssociatedTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyTagDto>>> {
    return setCompanyAssociatedTags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCompanyAssociatedTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCompanyAssociatedTags(params: SetCompanyAssociatedTags$Params, context?: HttpContext): Observable<Array<CompanyTagDto>> {
    return this.setCompanyAssociatedTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyTagDto>>): Array<CompanyTagDto> => r.body)
    );
  }

  /** Path part for operation `getCompanyCategories()` */
  static readonly GetCompanyCategoriesPath = '/api/company-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyCategories$Response(params?: GetCompanyCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyCategoriesResponse>>> {
    return getCompanyCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyCategories(params?: GetCompanyCategories$Params, context?: HttpContext): Observable<Array<CompanyCategoriesResponse>> {
    return this.getCompanyCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyCategoriesResponse>>): Array<CompanyCategoriesResponse> => r.body)
    );
  }

  /** Path part for operation `getCompanyDetail()` */
  static readonly GetCompanyDetailPath = '/api/company/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDetail$Response(params: GetCompanyDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyDetailResponse>> {
    return getCompanyDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDetail(params: GetCompanyDetail$Params, context?: HttpContext): Observable<CompanyDetailResponse> {
    return this.getCompanyDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyDetailResponse>): CompanyDetailResponse => r.body)
    );
  }

  /** Path part for operation `getCompanyRoles()` */
  static readonly GetCompanyRolesPath = '/api/company-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyRoles$Response(params?: GetCompanyRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyRolesResponse>>> {
    return getCompanyRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyRoles(params?: GetCompanyRoles$Params, context?: HttpContext): Observable<Array<CompanyRolesResponse>> {
    return this.getCompanyRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyRolesResponse>>): Array<CompanyRolesResponse> => r.body)
    );
  }

  /** Path part for operation `getCompanySummary()` */
  static readonly GetCompanySummaryPath = '/api/company/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanySummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanySummary$Response(params?: GetCompanySummary$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanySummaryResponse>> {
    return getCompanySummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanySummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanySummary(params?: GetCompanySummary$Params, context?: HttpContext): Observable<CompanySummaryResponse> {
    return this.getCompanySummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanySummaryResponse>): CompanySummaryResponse => r.body)
    );
  }

  /** Path part for operation `getFollowedCompanies()` */
  static readonly GetFollowedCompaniesPath = '/api/company/followed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFollowedCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowedCompanies$Response(params?: GetFollowedCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FollowedCompanyResponse>>> {
    return getFollowedCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFollowedCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowedCompanies(params?: GetFollowedCompanies$Params, context?: HttpContext): Observable<Array<FollowedCompanyResponse>> {
    return this.getFollowedCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FollowedCompanyResponse>>): Array<FollowedCompanyResponse> => r.body)
    );
  }

  /** Path part for operation `registerCompanyByInvitationCode()` */
  static readonly RegisterCompanyByInvitationCodePath = '/api/company/register-by-invitation-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerCompanyByInvitationCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerCompanyByInvitationCode$Response(params: RegisterCompanyByInvitationCode$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerCompanyByInvitationCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerCompanyByInvitationCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerCompanyByInvitationCode(params: RegisterCompanyByInvitationCode$Params, context?: HttpContext): Observable<void> {
    return this.registerCompanyByInvitationCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registerCompanyByInvitationCodeOtp()` */
  static readonly RegisterCompanyByInvitationCodeOtpPath = '/api/company/register-by-invitation-code-otp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerCompanyByInvitationCodeOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerCompanyByInvitationCodeOtp$Response(params: RegisterCompanyByInvitationCodeOtp$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerCompanyByInvitationCodeOtp(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerCompanyByInvitationCodeOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerCompanyByInvitationCodeOtp(params: RegisterCompanyByInvitationCodeOtp$Params, context?: HttpContext): Observable<void> {
    return this.registerCompanyByInvitationCodeOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `saveStripeAmbassadorPurchaseSessionDetailsFailed()` */
  static readonly SaveStripeAmbassadorPurchaseSessionDetailsFailedPath = '/api/company/ambassador-purchase-failed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveStripeAmbassadorPurchaseSessionDetailsFailed()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeAmbassadorPurchaseSessionDetailsFailed$Response(params: SaveStripeAmbassadorPurchaseSessionDetailsFailed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveStripeAmbassadorPurchaseSessionDetailsFailed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveStripeAmbassadorPurchaseSessionDetailsFailed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeAmbassadorPurchaseSessionDetailsFailed(params: SaveStripeAmbassadorPurchaseSessionDetailsFailed$Params, context?: HttpContext): Observable<void> {
    return this.saveStripeAmbassadorPurchaseSessionDetailsFailed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `saveStripeAmbassadorPurchaseSessionDetailsSuccess()` */
  static readonly SaveStripeAmbassadorPurchaseSessionDetailsSuccessPath = '/api/company/ambassador-purchase-success';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveStripeAmbassadorPurchaseSessionDetailsSuccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeAmbassadorPurchaseSessionDetailsSuccess$Response(params: SaveStripeAmbassadorPurchaseSessionDetailsSuccess$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveStripeAmbassadorPurchaseSessionDetailsSuccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveStripeAmbassadorPurchaseSessionDetailsSuccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeAmbassadorPurchaseSessionDetailsSuccess(params: SaveStripeAmbassadorPurchaseSessionDetailsSuccess$Params, context?: HttpContext): Observable<void> {
    return this.saveStripeAmbassadorPurchaseSessionDetailsSuccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `toggleFollow()` */
  static readonly ToggleFollowPath = '/api/company/{id}/toggle-follow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleFollow()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleFollow$Response(params: ToggleFollow$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return toggleFollow(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleFollow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleFollow(params: ToggleFollow$Params, context?: HttpContext): Observable<void> {
    return this.toggleFollow$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
