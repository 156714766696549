/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAdvancedServicesUnfairTerms } from '../fn/document/get-advanced-services-unfair-terms';
import { GetAdvancedServicesUnfairTerms$Params } from '../fn/document/get-advanced-services-unfair-terms';
import { getContacts } from '../fn/document/get-contacts';
import { GetContacts$Params } from '../fn/document/get-contacts';
import { getCookiePolicy } from '../fn/document/get-cookie-policy';
import { GetCookiePolicy$Params } from '../fn/document/get-cookie-policy';
import { GetDocumentQuestionAnswerResponse } from '../models/get-document-question-answer-response';
import { GetDocumentResponse } from '../models/get-document-response';
import { getDummies } from '../fn/document/get-dummies';
import { GetDummies$Params } from '../fn/document/get-dummies';
import { getFaqs } from '../fn/document/get-faqs';
import { GetFaqs$Params } from '../fn/document/get-faqs';
import { getGoldAmbassadorUnfairTerms } from '../fn/document/get-gold-ambassador-unfair-terms';
import { GetGoldAmbassadorUnfairTerms$Params } from '../fn/document/get-gold-ambassador-unfair-terms';
import { getPrivacyPolicy } from '../fn/document/get-privacy-policy';
import { GetPrivacyPolicy$Params } from '../fn/document/get-privacy-policy';
import { getRegulation } from '../fn/document/get-regulation';
import { GetRegulation$Params } from '../fn/document/get-regulation';
import { getSilverAmbassadorUnfairTerms } from '../fn/document/get-silver-ambassador-unfair-terms';
import { GetSilverAmbassadorUnfairTerms$Params } from '../fn/document/get-silver-ambassador-unfair-terms';
import { getTechnicalSupport } from '../fn/document/get-technical-support';
import { GetTechnicalSupport$Params } from '../fn/document/get-technical-support';
import { getTermsAndConditions } from '../fn/document/get-terms-and-conditions';
import { GetTermsAndConditions$Params } from '../fn/document/get-terms-and-conditions';
import { getUnfairTerms } from '../fn/document/get-unfair-terms';
import { GetUnfairTerms$Params } from '../fn/document/get-unfair-terms';
import { search } from '../fn/document/search';
import { Search$Params } from '../fn/document/search';
import { SearchDocumentDto } from '../models/search-document-dto';

@Injectable({ providedIn: 'root' })
export class DocumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAdvancedServicesUnfairTerms()` */
  static readonly GetAdvancedServicesUnfairTermsPath = '/api/documents/advanced-services-unfair-terms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdvancedServicesUnfairTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvancedServicesUnfairTerms$Response(params?: GetAdvancedServicesUnfairTerms$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDocumentResponse>> {
    return getAdvancedServicesUnfairTerms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdvancedServicesUnfairTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvancedServicesUnfairTerms(params?: GetAdvancedServicesUnfairTerms$Params, context?: HttpContext): Observable<GetDocumentResponse> {
    return this.getAdvancedServicesUnfairTerms$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDocumentResponse>): GetDocumentResponse => r.body)
    );
  }

  /** Path part for operation `getContacts()` */
  static readonly GetContactsPath = '/api/documents/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContacts$Response(params?: GetContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDocumentResponse>> {
    return getContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContacts(params?: GetContacts$Params, context?: HttpContext): Observable<GetDocumentResponse> {
    return this.getContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDocumentResponse>): GetDocumentResponse => r.body)
    );
  }

  /** Path part for operation `getCookiePolicy()` */
  static readonly GetCookiePolicyPath = '/api/documents/cookie-policy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCookiePolicy()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCookiePolicy$Response(params?: GetCookiePolicy$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDocumentResponse>> {
    return getCookiePolicy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCookiePolicy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCookiePolicy(params?: GetCookiePolicy$Params, context?: HttpContext): Observable<GetDocumentResponse> {
    return this.getCookiePolicy$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDocumentResponse>): GetDocumentResponse => r.body)
    );
  }

  /** Path part for operation `getDummies()` */
  static readonly GetDummiesPath = '/api/documents/dummies/{dummyType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDummies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDummies$Response(params: GetDummies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetDocumentResponse>>> {
    return getDummies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDummies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDummies(params: GetDummies$Params, context?: HttpContext): Observable<Array<GetDocumentResponse>> {
    return this.getDummies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetDocumentResponse>>): Array<GetDocumentResponse> => r.body)
    );
  }

  /** Path part for operation `getFaqs()` */
  static readonly GetFaqsPath = '/api/documents/faq';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFaqs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqs$Response(params?: GetFaqs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetDocumentQuestionAnswerResponse>>> {
    return getFaqs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFaqs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqs(params?: GetFaqs$Params, context?: HttpContext): Observable<Array<GetDocumentQuestionAnswerResponse>> {
    return this.getFaqs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetDocumentQuestionAnswerResponse>>): Array<GetDocumentQuestionAnswerResponse> => r.body)
    );
  }

  /** Path part for operation `getGoldAmbassadorUnfairTerms()` */
  static readonly GetGoldAmbassadorUnfairTermsPath = '/api/documents/gold-ambassador-unfair-terms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGoldAmbassadorUnfairTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoldAmbassadorUnfairTerms$Response(params?: GetGoldAmbassadorUnfairTerms$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDocumentResponse>> {
    return getGoldAmbassadorUnfairTerms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGoldAmbassadorUnfairTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoldAmbassadorUnfairTerms(params?: GetGoldAmbassadorUnfairTerms$Params, context?: HttpContext): Observable<GetDocumentResponse> {
    return this.getGoldAmbassadorUnfairTerms$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDocumentResponse>): GetDocumentResponse => r.body)
    );
  }

  /** Path part for operation `getPrivacyPolicy()` */
  static readonly GetPrivacyPolicyPath = '/api/documents/privacy-policy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivacyPolicy()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrivacyPolicy$Response(params?: GetPrivacyPolicy$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDocumentResponse>> {
    return getPrivacyPolicy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPrivacyPolicy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrivacyPolicy(params?: GetPrivacyPolicy$Params, context?: HttpContext): Observable<GetDocumentResponse> {
    return this.getPrivacyPolicy$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDocumentResponse>): GetDocumentResponse => r.body)
    );
  }

  /** Path part for operation `getRegulation()` */
  static readonly GetRegulationPath = '/api/documents/regulation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegulation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegulation$Response(params?: GetRegulation$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDocumentResponse>> {
    return getRegulation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegulation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegulation(params?: GetRegulation$Params, context?: HttpContext): Observable<GetDocumentResponse> {
    return this.getRegulation$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDocumentResponse>): GetDocumentResponse => r.body)
    );
  }

  /** Path part for operation `getSilverAmbassadorUnfairTerms()` */
  static readonly GetSilverAmbassadorUnfairTermsPath = '/api/documents/silver-ambassador-unfair-terms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSilverAmbassadorUnfairTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSilverAmbassadorUnfairTerms$Response(params?: GetSilverAmbassadorUnfairTerms$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDocumentResponse>> {
    return getSilverAmbassadorUnfairTerms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSilverAmbassadorUnfairTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSilverAmbassadorUnfairTerms(params?: GetSilverAmbassadorUnfairTerms$Params, context?: HttpContext): Observable<GetDocumentResponse> {
    return this.getSilverAmbassadorUnfairTerms$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDocumentResponse>): GetDocumentResponse => r.body)
    );
  }

  /** Path part for operation `getTechnicalSupport()` */
  static readonly GetTechnicalSupportPath = '/api/documents/technical-support';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTechnicalSupport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTechnicalSupport$Response(params?: GetTechnicalSupport$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetDocumentQuestionAnswerResponse>>> {
    return getTechnicalSupport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTechnicalSupport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTechnicalSupport(params?: GetTechnicalSupport$Params, context?: HttpContext): Observable<Array<GetDocumentQuestionAnswerResponse>> {
    return this.getTechnicalSupport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetDocumentQuestionAnswerResponse>>): Array<GetDocumentQuestionAnswerResponse> => r.body)
    );
  }

  /** Path part for operation `getTermsAndConditions()` */
  static readonly GetTermsAndConditionsPath = '/api/documents/terms-and-conditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTermsAndConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsAndConditions$Response(params?: GetTermsAndConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDocumentResponse>> {
    return getTermsAndConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTermsAndConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsAndConditions(params?: GetTermsAndConditions$Params, context?: HttpContext): Observable<GetDocumentResponse> {
    return this.getTermsAndConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDocumentResponse>): GetDocumentResponse => r.body)
    );
  }

  /** Path part for operation `getUnfairTerms()` */
  static readonly GetUnfairTermsPath = '/api/documents/unfair-terms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnfairTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnfairTerms$Response(params?: GetUnfairTerms$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDocumentResponse>> {
    return getUnfairTerms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnfairTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnfairTerms(params?: GetUnfairTerms$Params, context?: HttpContext): Observable<GetDocumentResponse> {
    return this.getUnfairTerms$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDocumentResponse>): GetDocumentResponse => r.body)
    );
  }

  /** Path part for operation `search()` */
  static readonly SearchPath = '/api/documents/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method doesn't expect any request body.
   */
  search$Response(params: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SearchDocumentDto>>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  search(params: Search$Params, context?: HttpContext): Observable<Array<SearchDocumentDto>> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SearchDocumentDto>>): Array<SearchDocumentDto> => r.body)
    );
  }

}
